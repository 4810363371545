* {
  font-weight: 700;
  color: #f5f5f5;
}

body {
  margin: 0;
  font-family: "Benton Sans Low-DPI",Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../src/assets/priroityview_dash.png);
  background-size: 100vw 100vh
  //background-image: url(../src/assets/BG.svg);
}

::-webkit-input-placeholder {
  text-align: left;
  line-height: normal;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;  
  line-height: normal;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: left;  
  line-height: normal;
}

:-ms-input-placeholder {  
  text-align: left; 
  line-height: normal;
}