@import "../mixins";
@import "../variables";

.loginTitle {
  @include display-flex();
  @include justify-content-center();
  font-size: 20px;
  margin-bottom: 45px;
  margin-top: 20px;
  color: white
}

.loginLogo {
  width: 280px;
  cursor: pointer;
}