@import "../mixins";
@import "../variables";
@import "../extensions";

.form {
  display: -ms-grid;
  display: grid;
  width: 260px;
  margin: -17px;
  background-color: white;
  border-radius: 17px;
  padding: 20px;
  @include media-max-width() {
    width: 95%;
  }
}

input {
  @include input-placeholder();
  padding-left: 15px;
}

.input {
  @extend .mainInputStyle;
  border: 0px solid $line-theme;
  margin-top: $main-margin;
  outline-color: $stone-theme;
  color: $stone-theme;
  background-color: #efefef;
  border-radius: 8px;

}
.loginBtn {
  @extend .mainInputStyle;
  margin-top: 21px;
  font-size: 17px;
  color: #ffffff;
  height: 45px;
  background-color: $sky-theme;
  border: 0px solid $line-theme;
  border-radius: 8px;
  margin-bottom: 20px;

  cursor: pointer;
  &:active {
    opacity: 50%;
  }
  &:hover {
    filter: invert(35%);
  }
}

.loginBtnDisabled {
  @extend .mainInputStyle;
  margin-top: 21px;
  font-size: 17px;
  color: #ffffff;
  height: 45px;
  background-color: $stone-theme;
  border: 0px solid $line-theme;
  border-radius: 8px;
  margin-bottom: 20px;

  cursor: not-allowed ;
  &:active {
    opacity: 50%;
  }
  &:hover {
    filter: invert(35%);
  }
}

.icon {
  position: absolute;
  margin-top: $main-margin * 2;
  margin-left: $main-margin * 1;
}

.forgot {
  font-size: 11px;
  font-weight: lighter;
  color: #353d57;
  margin-top: 15px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.loginInstructions {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #353d57;
  text-align: center;
}

.bottomtext {
  margin-top: 59px;
  font-size: 12px;
  font-weight: normal;
  color: $stone-theme;
  text-align: justify;
  width: 556px;
  text-align: center;

}

.modal {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

.logincontainer{
  display: contents;
}

.alerticon {
  height: 30px;
}

.modaltext {
  color: black;
}

.backButton {
  margin-top: 45px;
  font-size: 15px;
  font-weight: normal;
  color: $stone-theme;
}

.backButton:hover {
  cursor: pointer;
}

.loginBottomLogo {
  margin-top: 50px;
  width: 280px;
  cursor: pointer;
}

.disclaimer {
  font-size: 10px; 
  color: #666;
  max-width: 50%;
}


.rememberMe {
  font-size: 12px; 
  color: #666;
  max-width: 50%;
  margin-top: 20px;
  left: -80px;
  position: relative;
}


@media only screen and (max-width: 600px) {
  .disclaimer {
    font-size: 8px; 
    color: #666;
    max-width: 90%;
  }
}