@import "../mixins";
@import "../variables";

.tab {
    display: inline-block;
    padding: 6px 7px;
    background-color: whitesmoke;
    color: #212529;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid transparent;
    border-top-left-radius: 6px;
    border-top-right-radius: 13px;
    transition: background-color 0.3s;
    margin-top: 11px;
    font-size: 14px;
  }
  
  .tab:hover {
    background-color: #bebdbd;
  }
  
  .tab.active {
    border-bottom-color: #212529;
    border-width: medium;
    background: whitesmoke;
    margin: 5px;
  }

  .breadcrumb {
    float: left;
    margin-top: 18px;
    margin-left: 8px;
    color: #212529;
  }

  // .breadcrumb:hover {
  //   cursor: pointer;
  //   color: #bebdbd;
  // }

  .logout {
    float: right;
    margin-top: 16px;
    margin-right: 8px;
    color: white;
    font-weight: normal;
    font-size: 16px;
    font-weight: 700;
  }

  .logout:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .tabs {
    //margin-left: calc( (100vw - 1600px) / 2)
    margin-left: 20px;
  }
  .auxtabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    width: 0;
    height: 0;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: #eaebf0;
  }

  .tabframe {
    border: 0;
    //margin-left: calc( (100vw - 1600px) / 2);
    //width: calc( (100vw - 40px));
    height: calc( (100vh - 45px));
    min-height: 900px;
    background-repeat: no-repeat, repeat;
    background-position: center;
    display:none;
    background-color: $back-color;
  }

  .icon {
    float: right;
    margin-top: 11px;
    margin-right: calc((101vw - 1600px) / 2);
    margin-right: 10px;
    cursor: pointer;
    height: 26px;
  }

  .menuicon {
    float: left;
    margin-top: 11px;
    margin-right: calc((101vw - 1600px) / 2);
    margin-left: 10px;
    cursor: pointer;
    height: 26px;
  }

  .pdficon {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    height: 29px;
  }

  .downloadIcon {
    float: right;
    cursor: pointer;
    height: 22px;
    margin-top: 12px;
  }
  .topWidget{
    margin-top: 10px;
  }

  .pwrpicon {
    float: right;
    margin-top: 10px;
    cursor: pointer;
    height: 25px;
    margin-right: 8px;
  }

  .excelicon {
    float: right;
    margin-top: 8px;
    cursor: pointer;
    height: 29px;
    margin-right: 10px;
  }

  .refreshicon {
    float: right;
    margin-top: 11px;
    cursor: pointer;
    height: 22px;
    margin-right: 10px;
  }

  .spin {
    transform-origin: center;
    animation: spin 1s linear infinite;
  }

  .subContainer {
    float: right;
    line-height: 50px;
    cursor: pointer;
  }

  .subCheckbox {
    cursor: pointer;
  }

  .subText {
    cursor: pointer;
  }

  @keyframes spin {
      from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
  }

  .gsicon {
    float: left;
    margin-right: 0;
    margin-left: 17px;
    margin-bottom: 10px;
    margin-top: 17px;
    cursor: pointer;
    height: 65px;
  }

  .dividericon {
    float: right;
    margin-top: 7px;
    height: 31px;
  }

  .toolbar {
    width: 100%;
    fill: black;
    background-color: $slate-theme;
    display: inline-block;
    margin-bottom: -3px;
    height: 40px;
  }

  .contentblock {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    margin-left: 190px;
    display: block;
    height: calc( (100vh ));
    width: calc( (100vw - 190px));
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }

  .contentblockMobile {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    display: block;
    height: calc( (100vh ));
    width: 100vw;
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }

  .mobileContentblock {
    //margin-left: 300px;
    //calc( (100vw - 300px));
    //display: block;
    display: block;
    height: calc( (100vh ));
    width: 100vw;
    display: inline-block;
    position: fixed !important;
    z-index: 1;
  }

  .dashboardblock {
    width: 100%;
    fill: black;
    display: inline-block;
    height: calc( (100vh - 45px));
    background-color: $back-color;
    overflow: auto;
  }

  .sidebar {
    width: 190px;
    height: calc( (100vh ));
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $slate-theme;
    display: block;
    position: fixed!important;
    z-index: 1001;
    overflow-y: auto;
    overflow-x: hidden;
    left: 0; /* Start position for the sidebar */
    transition: left 0.5s ease, width 0.5s ease; /* Add transition */
    text-align: center;
  }

  .withscrollbar {
    margin-left: -11px;
  }

  .sidebarClosed {
    width: 0px;
    height: calc( (100vh));
    fill: black;
    border-bottom-color: $sky-theme;
    border-width: medium;
    border-bottom-style: solid;
    background-color: $slate-theme;
    display: block;
    position: fixed!important;
    z-index: 1;
    overflow: auto;
  }

  .sidebarlogo {
    text-align: center;
    align-items: center;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }
  .sidebarlogoCircle {
    margin-top: 56px;
    width: 150px;
    height: 130px;
    border-radius: 50%;
    background-color: white;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    position: relative;
    align-items: center;
  }

  .sidebarState {
    margin-top: 10px;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    width: 150px;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-top: 20px;
  }

  .sideHeader {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid white;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
  }

  .sidePortalName{
    text-align: center;
    bottom: 0;
    font-size: 23px;
    width: 100%;
    margin-top: max(10px, calc(100vh - 800px));
  }

  .sideState {
    height: 50px;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-bottom:40px;
    font-size: 14px;
    font-weight: normal;
  }

  .sideButton {
    height: 40px;
    width: 100%;
    border-color: white;
    border-width: medium;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-weight: normal;
    background-color: #3c5e87;
    margin: 1px;
    overflow: hidden;
  }

  .sideDownload {
    text-align: center;
    position: absolute;
    bottom: 0;
    margin-bottom: 80px;
    font-size: 23px;
    width: 100%;
  }

  .sideDownload:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .sideButton.active {
    background-color: #fab400;
    color: #3c5e87;
  }

  .sideButton.active:hover {
    color: #f5f5f5;
  }

  .sideButton:hover {
    cursor: pointer;
    color: #f5f5f5;
  }

  .tabbar {
    width: 100%;
    fill: black;
    background-color: $back-color;
  }

  .landing {
    overflow: auto;
    //border-bottom-color: $line-theme;
    /* border-bottom-color: black; */
    //border-width: medium;
    //border-bottom-style: solid;
    //display: inline-block;
    width: 100%;
  }

  .dashboardContainer {
    height: 100%;
  }

  .loadingSpinnerContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    z-index: 9999;
    top:0
  }
  
  /* Styles for the spinning wheel */
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #ffffff; /* Adjust the color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: fixed;
  }
  
  /* Animation for spinning */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media all and (max-width: 1600px) {
    .tabframe {
        border: 0;
        background-color: $back-color;
        margin-left: 0;
        width: 100%;
        height: calc( (100vh - 45px)) !important;
        min-height: 900px;
        background-repeat: no-repeat, repeat;
        background-position: center;
        overflow: auto;
      }

      .tabs {
        margin-left: 0
      }

      .icon {
        margin-right: 10px;
      }
 }

 .toolbarMobileLogo {
  float: left;
  margin-top: 11px;
  margin-right: calc((131vw - 1600px) / 2);
  margin-left: 50px;
  cursor: pointer;
  height: 26px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
  z-index: 1000; /* Ensure it covers the content but is behind the sidebar */
}

.sidebarContactSupport {
  font-size: 15px;
  line-height: 4px;
  display: inline-flex;
  margin-top: 10px;
  cursor: pointer;
}

.sidebarContactSupportText {
  margin-top: 12px;
}

.sidebarContactSupportImage {
  width: 25px;
  margin-right: 5px;
}