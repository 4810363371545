@import "mixins";
@import "variables";

.spinnerContainer {
    position: fixed;
    top: 70%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  /* Styles for the spinning wheel */
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #ffffff; /* Adjust the color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: fixed;
  }
  
  /* Animation for spinning */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .alerticon{
    height: 25px;
  }

  .modaltext {
    max-width: 670px;
    text-align: left;
    font-weight: 100;
    font-size: 16px;
    line-height: 1.7;
    color: black
  }
  

  .container {
    position: relative;
  }
  
  .spinnerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; /* Ensure it overlays the Landing component */
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }